function __returnsJSX(dataLocal: any, attributes: any[]) {
  let jsx = [];
  for (let index = 0; index < dataLocal.neededInputs; index++) {
    jsx.push(
      <>
        <label className="inputBlock__label">
          {dataLocal.textToInputs[index]}
        </label>
        <input {...attributes[index]} className="inputBlock__input" />
      </>
    );
  }
  return jsx;
}
export default function giveInputs(
  dataLocal: any,
  refs: { emailRef: any; passwordRef: any }
) {
  const attributes: Array<any> = [
    { ref: refs.emailRef, maxLength: 60, type: "text" },
    { ref: refs.passwordRef, maxLength: 16, type: "password" },
  ];
  return __returnsJSX(dataLocal, attributes);
}
