import { Helmet } from "react-helmet-async";
import "./Authorization.style.scss";
import AuthorizationVariantModule from "./AuthorizationVariant.module";
import { useState } from "react";

function Authorization() {
  const [text, setText] = useState<{ h1: string; h3: string }>({
    h1: "",
    h3: "",
  });
  return (
    <main className="Authorization">
      <Helmet>
        <title>Authorization page</title>
        <meta
          name="description"
          content="Welcome! To the authorization page of Check API. You can create an account or Log-in here."
        />
        <link rel="canonical" href={`https://checkapi.app/authorization`}></link>
      </Helmet>
      <div className="authorization__inner">
        <article className="authorization__direction">
          <h1 className="authorization__welcome">{text.h1}</h1>
          <h3 className="authorization__label">{text.h3}</h3>
          <div className="inputBlock">
            <section className="inputBlock__inner">
              <AuthorizationVariantModule text={text} setText={setText} />
            </section>
          </div>
        </article>
      </div>
    </main>
  );
}
export default Authorization;
