import { SetState } from "../../../../../contextAPI/contex.interface";
import createUUID from "../../../../../helper/creatUUID.helper";

interface IChangeQueryFromInput {
  theLongestArr: Array<unknown>;
  inputWithId: Array<string>;
  onlyKeys: Array<string>;
  onlyValues: Array<string>;
  setState: SetState;
}
export default function changeQuerryRealTime({
  setState,
  theLongestArr,
  inputWithId,
  onlyKeys,
  onlyValues,
}: IChangeQueryFromInput) {
  theLongestArr.map((prop: any, index: any) => {
    const id = inputWithId[index] || null;
    const bothIsNull = !onlyKeys[index] && !onlyValues[index];
    const className = bothIsNull
      ? "check__check unClicked"
      : "check__check clicked";
    const newObj = {
      [id ? id : createUUID()]: {
        isClicked: !bothIsNull,
        name: onlyKeys[index] || "",
        value: onlyValues[index] || "",
        className: className,
      },
    };
    setState({ type: "query", payload: newObj });
  });
}
