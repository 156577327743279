import {useEffect} from 'react';
export default function useChangeText(locations: any, text: any, setText: any){
    useEffect(()=>{
        if(locations.matchpleaseConfirm){
            setText({h1: 'Success!', h3: 'Please confirm the link that was sent to your email address'})
        }
        if(locations.matchcreateAccount){
            setText({h1: 'The last step', h3: 'Please, create your login and password'})
        }
        if(locations.matchsignin){
            setText({h1: 'Welcome', h3: 'SignIn'})
        }
        if(locations.matchsignup){
            setText({h1: 'Welcome', h3: 'SignUp'})
        }
        if(locations.matchNewPassword){
            setText({h1: 'You can change password now', h3: 'Please create a new password'})
        }
        if(locations.matchResetPassword){
            setText({h1: 'Please specify email that you uses', h3: 'A verification link will be sent to this email'})
        }
    }, [JSON.stringify(locations)])
}