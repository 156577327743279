import ValueCheckService from "./ValueCheck.service";

function ValueCheckModule({ name }: { name: any }) {
  const {inputs} = ValueCheckService(name);
  return (
    <div className="ValueCheck">
      <div className="ValueCheck__inner">
        <div className="ValueCheck__direction" style={{ paddingRight: "10px" }}>
          {[...inputs]}
        </div>
      </div>
    </div>
  );
}
export default ValueCheckModule;
