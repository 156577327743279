import { useEffect } from "react";
import filterToClicked from "../helper/filterToClicked.helper";
import selectedToInput from "../helper/selectedToInput.helper";

interface IUseWheQuery {
  query: any;
  inputState: any;
  setInputWithID: any;
  setInputState: any;
}
export default function useWhenQuery({
  query,
  inputState,
  setInputWithID,
  setInputState
}: IUseWheQuery) {
  useEffect(() => {
    const objectIntoArr = Object.keys(query);
    if (objectIntoArr) {
      const selectedValues = filterToClicked(objectIntoArr, query);
      const {idArr, final} = selectedToInput(selectedValues, query);
      const clearText = inputState.split("?")[0];
      setInputState(clearText + final);
      setInputWithID(idArr);
    }
  }, [JSON.stringify(query)]);
}
