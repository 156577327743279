import { useMatch } from "react-router-dom";

export function RouteStatic() {
  const matchsignin = useMatch("/authorization/signin");
  const matchsignup = useMatch("/authorization/signup");
  const matchpleaseConfirm = useMatch("/authorization/pleaseconfirm");
  const matchcreateAccount = useMatch("/authorization/createAccount");
  const matchlogout = useMatch("/authorization/logout");
  const matchResetPassword = useMatch("/authorization/resetPassword");
  const matchNewPassword = useMatch("/authorization/newPassword");
  return {
    matchsignin,
    matchsignup,
    matchpleaseConfirm,
    matchlogout,
    matchcreateAccount,
    matchResetPassword,
    matchNewPassword,
  };
}
