import { useNavigate, useRouteError } from "react-router-dom";
import { useState, useEffect } from "react";

import "./Error.style.scss";
import { Helmet } from "react-helmet-async";
function ErrorPage() {
  const error: any = useRouteError();
  const router = useNavigate();
  const [message, setMessage] = useState<string>("");
  useEffect(() => {
    const message = localStorage.getItem("error");
    if (message) {
      setMessage(message);
    }
    return () => {
      localStorage.removeItem("error");
    };
  }, []);
  return (
    <div className="error">
      <Helmet>
        <title>Error!</title>
        <meta
          name="description"
          content="Oops, this page tells that something is odd. Please, contact us if you are watching this for too long."
        />
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <main className="error__inner">
        <article className="error__direction">
          <h1 className="error__error">
            {(message && message) ||
              (error &&
                error.status !== 404 &&
                "if you see this, then it`s probably my fault, contact me") ||
              "Hello, if you see this, you probably misspelled the URL."}
          </h1>
          <button
            onClick={() => router("/")}
            className="error__btn"
          >
            {error ? "Go Back" : "Back to the home page"}
          </button>
        </article>
      </main>
    </div>
  );
}
export default ErrorPage;
