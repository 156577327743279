import { FormEvent } from "react";
import { SetState } from "../../../../../contextAPI/contex.interface";

interface IOnSubmitHelper {
  inputState: string;
  setState: SetState;
}
export default function onSubmitHelper({
  inputState,
  setState,
}: IOnSubmitHelper) {
  function onSubmit(e: FormEvent<HTMLElement>) {
    e.preventDefault();
    if (inputState) {
      setState({
        type: "contentData",
        payload: { data: "Please wait...", isSubmited: true },
      });
    }
  }
  return { onSubmit };
}
