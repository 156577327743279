import { useNavigate } from "react-router-dom";
import "./CreateAccount.style.scss";
import ButtonModule from "../ButtonForShadows/ButtonModule";

function CreateAccount({ shadow, setState }: { shadow?: any; setState?: any }) {
  const navigate = useNavigate();
  const onYes = () => {
    setState({ type: "shadow", payload: { ...shadow, canCreateAcc: false } });
    navigate("/authorization/createAccount");
  };
  return (
    <div className="createAccount">
      <div className="createAccount__btnBlock">
        <ButtonModule className="createAccount__btn" onClick={onYes}>
          Create account
        </ButtonModule>
        <ButtonModule
          className="createAccount__btn"
          onClick={() =>
            setState({
              type: "shadow",
              payload: { ...shadow, canCreateAcc: false },
            })
          }
        >
          No,maybe later
        </ButtonModule>
      </div>
    </div>
  );
}
export default CreateAccount;
