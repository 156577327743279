import { Reducer, useReducer } from "react";
import { IInit } from "../AuthorizationVariant.module";

interface IAction {
  type: string;
  payload: any;
}
function __reducer(initialState: IInit): any {
  return (
    state: IInit,
    action: IAction = { type: "", payload: initialState }
  ) => {
    const defaultMaker = Object.assign({}, state, { ...action.payload });
    return defaultMaker;
  };
}
export default function useLocalState(initialState: any) {
  const [dataLocal, dispatch] = useReducer<Reducer<IInit, IAction>>(
    __reducer(initialState),
    initialState
  );
  return { dataLocal, dispatch };
}
