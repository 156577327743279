import NotAuthorizated from "../Authorization/NotAuthorizated";
import LoadingModule from "../Loading/Loading.module";
import NotificationModule from "../Notification/Notification.module";
import DialogWrapper from "../../../Wrappers/Dialog/DialogComponent";
import UploadModule from "../Upload/Upload.module";
import CreateAccount from "../CreateAccount/CreateAccount.module";
import FullcontentModule from "../FullContent/Fullcontent.module";
import { useContext } from "react";
import { clientContext } from "../../../contextAPI";
import { contextValue } from "../../../contextAPI/contex.interface";

function ShadowContent() {
  const {
    state: { shadow },
    setState,
  } = useContext<contextValue>(clientContext);
  const notificationObj = shadow.isNotification
    ? {
        onClickFrom: shadow.isNotification.fn,
        yesText: shadow.isNotification.yesText,
        noText: shadow.isNotification.noText,
      }
    : null;
  return (
    <>
      <FullcontentModule shadow={shadow} setState={setState} />
      {shadow.isLoading && <LoadingModule />}
      <DialogWrapper
        shadow={shadow}
        setState={setState}
        booleanName="isUploadClicked"
        text=" Absolutly free, but you need to sign-in or sign-up first."
      >
        <NotAuthorizated />
      </DialogWrapper>
      <DialogWrapper
        shadow={shadow}
        setState={setState}
        booleanName="isNotification"
        text={
          shadow.isNotification
            ? shadow.isNotification.mainText
            : "If you see this... to bad then)"
        }
      >
        <NotificationModule {...notificationObj} />
      </DialogWrapper>
      <DialogWrapper
        shadow={shadow}
        setState={setState}
        booleanName="isSuggestion"
        text={"You can download or reset the background"}
      >
        <UploadModule />
      </DialogWrapper>
      <DialogWrapper
        shadow={shadow}
        setState={setState}
        booleanName="canCreateAcc"
        text="You have confirmed the link and can now create an account"
      >
        <CreateAccount />
      </DialogWrapper>
    </>
  );
}
export default ShadowContent;
