import "./Check.style.scss";
import { ChangeEventHandler, CSSProperties } from "react";
import ReturnThemeHelper from "../../../../../helper/returnThemeChange.helper";

export interface ICheck {
  text?: string;
  id?: string | undefined;
  onClick: ChangeEventHandler<HTMLInputElement>;
  turn?: boolean;
  disable?: boolean;
  className?: string;
  style?: CSSProperties;
  name?: any;
}
function CheckModule({
  text,
  id,
  onClick,
  turn,
  disable = true,
  style,
  className = undefined,
  name = undefined,
}: ICheck) {
  const { backgroundColorInput } = ReturnThemeHelper();
  return (
    <div className="check">
      <div className="check__inner" style={style}>
        <div className="check__direction">
          <input
            aria-label="check button as in checkbox"
            disabled={!disable}
            style={{ backgroundColor: "white" }}
            id={id}
            type="checkbox"
            className={className || `check__check`}
            onChange={onClick}
            checked={turn}
            name={name}
          />
          {text && (
            <p className="check__text" style={{ color: backgroundColorInput }}>
              {text}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
export default CheckModule;
