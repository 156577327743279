import { useContext, useEffect, useState } from "react";
import { clientContext } from "../../../contextAPI";
import { contextValue } from "../../../contextAPI/contex.interface";
import { logout } from "../../../restAPI/api/userAPI";
import { useNavigate } from "react-router-dom";

export default async function useRedirect(locations: any) {
  const navigate = useNavigate();
  const [loagingOver, setLoadingOver] = useState<boolean>(false);
  const {
    state: { user, shadow },
  } = useContext<contextValue>(clientContext);
  if (locations.matchlogout) {
    user.isLoggined && await logout() || navigate('/');
  }
  useEffect(() => {
    const isUserExist = user.data ? true : false;
    if (loagingOver) {
      if (isUserExist) {
        __ifLoggined();
      } else {
        __ifGuest();
      }
    }
  }, [loagingOver]);
  useEffect(() => {
    return () => {
      if (shadow.isLoading) {
        setLoadingOver(true);
      }
    };
  }, [shadow.isLoading]);

  function __ifGuest() {
    if (
      !locations.matchResetPassword &&
      !locations.matchsignin &&
      !locations.matchsignup
    ) {
      navigate("/");
    }
  }

  function __ifLoggined() {
    __ifNotLimitedUser();
    __ifConfirmLinkUser();
    __ifActivatedUser();
  }
  function __ifActivatedUser() {
    if (locations.matchcreateAccount && !user.data.isActivated) {
      navigate("/");
    }
  }

  function __ifConfirmLinkUser() {
    if (locations.matchpleaseConfirm && !user.data.token.confirmLink) {
      navigate("/");
    }
  }
  function __ifNotLimitedUser() {
    if (user.isLoggined) {
      if (!locations.matchlogout) {
        navigate("/");
      }
      if (!user.data.isNewPassNeeded && locations.matchNewPassword) {
        navigate("/");
      }
    }
    else{
      if (locations.matchNewPassword) {
        navigate("/");
      }
    }
  }
}
