import { useNavigate } from "react-router-dom";
import SetBackground from "../../../helper/setBackground.helper";
import {
  _NavigateToError,
  _setUserAndNavigate,
} from "../../../helper/tokenSave.helper";
import { deleteImage } from "../../../restAPI/api/personalAPI";
import "./Upload.style.scss";
import ButtonModule from "../ButtonForShadows/ButtonModule";
export default function UploadModule({
  shadow,
  setState,
}: {
  shadow?: any;
  setState?: any;
}) {
  const navigate = useNavigate();
  const onUnSet = async () => {
    SetBackground(null);
    const decoded = await deleteImage();
    _NavigateToError(decoded, navigate) ||
      _setUserAndNavigate(setState, null, decoded);
    setState({ type: "shadow", payload: { isLoading: true } });
    window.location.reload();
  };
  return (
    <div className="upload">
      <div className="upload__btnBlock">
        <ButtonModule
          className="upload__btn"
          onClick={() => shadow.isSuggestion.fun.click()}
        >
          Upload
        </ButtonModule>
        {(
          document.getElementById("backgroundImage") || {
            style: { background: "" },
          }
        ).style.background.includes("url") ? (
          <ButtonModule className="upload__btn" onClick={async () => onUnSet()}>
            Reset background
          </ButtonModule>
        ) : null}
      </div>
    </div>
  );
}
