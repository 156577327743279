import DisplayModule from "./Display/Display.module";
import FormModule from "./Form/Form.module";
import Options from "./Options/Options.module";
import "./Section.style.scss";

function SectionModule() {
  return (
    <section className="section">
      <div className="section__inner">
        <div className="section__direction">
          <Options />
          <div className="section__display">
            <DisplayModule />
            <FormModule/>
          </div>
        </div>
      </div>
    </section>
  );
}
export default SectionModule;
