import { useRef, RefObject, useEffect, useState, CSSProperties } from "react";
import "./ScrollButton.style.scss";
export default function ScrollButtonModule({
  refOfElement,
  isNeeded = false,
}: {
  refOfElement: RefObject<HTMLElement>;
  isNeeded: boolean;
}) {
  const [isButtonPressed, setIsButtonPressed] = useState(false);
  const [style, setStyle] = useState<CSSProperties>({});
  const speed = 20;
  useEffect(() => {
    if (isNeeded) {
      divRef.current?.setAttribute("style", "display: block;");
    } else {
      divRef.current?.setAttribute("style", "display: none");
    }
  }, [isNeeded]);

  const divRef = useRef<HTMLDivElement>(null);
  let timer = useRef<any>(null);
  useEffect(() => {
    if (!isButtonPressed && timer.current) {
      clearInterval(timer.current);
    }
  }, [isButtonPressed]);
  function scrollToLeft() {
    if (refOfElement.current) {
      let howLong = refOfElement.current.scrollLeft - speed;
      onClick(howLong);
      ifInterval();
      timer.current = setInterval(() => {
        //@ts-ignore
        howLong = refOfElement.current.scrollLeft - speed;
        onClick(howLong);
      }, 100);
    }
  }
  function scrollToRight() {
    if (refOfElement.current) {
      let howLong = refOfElement.current.scrollLeft + speed;
      onClick(howLong);
      ifInterval();
      timer.current = setInterval(() => {
        //@ts-ignore
        howLong = refOfElement.current.scrollLeft + speed;
        onClick(howLong);
      }, 100);
    }
  }
  function checkWhatWasPressed(e: any) {
    switch (e.target.id) {
      case "left":
        scrollToLeft();
        break;
      case "right":
        scrollToRight();
        break;
    }
  }
  function ifInterval() {
    if (timer.current) {
      clearInterval(timer.current);
    }
  }
  const onClick = (num: number) => {
    refOfElement.current?.scrollTo({
      left: num,
    });
  };
  const handleButtonPress = (e: any) => {
    if (!isButtonPressed) {
      setIsButtonPressed(true);
      checkWhatWasPressed(e);
    }
  };
  const handleButtonRelease = (e: any) => {
    if (isButtonPressed) {
      setIsButtonPressed(false);
      checkWhatWasPressed(e);
    }
  };
  useEffect(() => {
    if (divRef.current && refOfElement.current) {
      const originalNum =
        divRef.current?.offsetLeft + refOfElement.current?.clientWidth / 2;
      const value = Math.floor(originalNum / 100) * 100 + 50;
      setStyle({
        left: `${value}px`,
      });
    }
  }, [divRef.current, refOfElement.current, isNeeded]);
  return (
    <div ref={divRef} className="buttons" style={style}>
      <img
        alt="drawn arrow pointing to the left. Is used as a button"
        title="click to moving the display area"
        draggable={false}
        className="buttons__imagesScroll"
        src="leftarrow.png"
        width={50}
        height={50}
        id="left"
        onMouseDown={handleButtonPress}
        onMouseUp={handleButtonRelease}
        onMouseLeave={handleButtonRelease}
        onTouchStart={handleButtonPress}
        onTouchEnd={handleButtonRelease}
      />
      <img
        alt="drawn arrow pointing to the right. Is used as a button"
        title="click to moving the display area"
        draggable={false}
        className="buttons__imagesScroll"
        src="leftarrow.png"
        style={{ transform: "scale(-1)" }}
        id="right"
        onMouseDown={handleButtonPress}
        onMouseLeave={handleButtonRelease}
        onMouseUp={handleButtonRelease}
        onTouchStart={handleButtonPress}
        onTouchEnd={handleButtonRelease}
        width={50}
        height={50}
      />
    </div>
  );
}
