import {
  ChangeEventHandler,
  MouseEventHandler,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import createUUID from "../../../helper/creatUUID.helper";
import { IInit } from "../../../contextAPI/contex.interface";
import { evalue } from "../helper/onChangeHelper.helper";
import InputModule from "../../Content/Section/Form/Input/Input.module";

interface IUseCustomEffect {
  state: IInit;
  setInputs: Dispatch<SetStateAction<JSX.Element[]>>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onClick: MouseEventHandler<HTMLInputElement>;
  oldState: Object;
  name: string;
}
export default function useCustomEffect({
  state,
  setInputs,
  onChange,
  onClick,
  name,
  oldState,
}: IUseCustomEffect) {
  const currentForm: evalue[] = state[name as keyof IInit];
  function _ifLastThenAdd(
    keys: string[],
    lastIndex: number,
    newArr: JSX.Element[]
  ) {
    const lastID: any = keys[lastIndex];
    if (currentForm[lastID].isClicked) {
      const keyID = createUUID();
      newArr.push(
        <InputModule
          id={keyID}
          key={keyID}
          onChange={onChange}
          onClick={onClick}
        />
      );
    }
  }
  function _addInArray(keys: string[], newArr: JSX.Element[]) {
    keys.map((prop: any) => {
      const current: evalue = currentForm[prop];
      newArr.push(
        <InputModule
          key={prop}
          id={prop}
          isClicked={current.isClicked}
          className={current.className}
          value={current.value}
          name={current.name}
          onChange={onChange}
          onClick={onClick}
        />
      );
    });
  }

  // main logic
  useEffect(() => {
    if (JSON.stringify(oldState) !== JSON.stringify(currentForm)) {
      const newArr: JSX.Element[] = [];
      const keys = Object.keys(currentForm);
      let lastIndex = keys.length - 1;
      if (keys.length > 0) {
        _addInArray(keys, newArr);
        _ifLastThenAdd(keys, lastIndex, newArr);
        setInputs(newArr);
      }
    }
  }, [JSON.stringify(currentForm)]);
}
