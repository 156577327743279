import CheckModule from "../../Options/Check/Check.module";
import {
  MouseEventHandler,
  ChangeEventHandler,
  ChangeEvent,
  useEffect,
  useState,
} from "react";
import "./Input.style.scss";
import InputHelper from "./Input.helper";

interface IInputModule {
  onClick?: MouseEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  name?: string | undefined;
  value?: string | undefined;
  id?: any;
  isClicked?: boolean;
  className?: string;
}
export default function InputModule({
  onClick,
  onChange,
  name = undefined,
  value = undefined,
  id,
  isClicked = false,
  className = "check__check",
}: IInputModule) {
  const {
    nameLocal,
    valueLocal,
    onChangeInput,
    onClickInput,
    stateClassName,
    stateClicked,
    setName,
    setValue
  } = InputHelper({
    className,
    isClicked,
    onChange,
    onClick,
    name,
    value,
  });
  const [isFocusedInput, setIsFocusedInput] = useState<boolean>(false);
  useEffect(() => {
    if (!isFocusedInput) {
      setName(name ? name : nameLocal);
      setValue(value ? value : valueLocal);
    }
  }, [name, value]);
  return (
    <div className="Input">
      <div className="input__inner">
        <div
          className="input__direction"
          onChange={(e: ChangeEvent<HTMLInputElement> & { value: Object }) =>
            onChangeInput(e)
          }
        >
          <CheckModule
            id={id}
            style={{ margin: "5px" }}
            onClick={onClickInput}
            turn={stateClicked}
            className={stateClassName}
            name="checkbox"
          />
          <div
            className="input__block"
            onFocus={() => setIsFocusedInput(true)}
            onBlur={() => setIsFocusedInput(false)}
          >
            <input
              id={id}
              name="name"
              value={nameLocal}
              type="text"
              className="input__text"
            />
            <input
              name="value"
              id={id}
              value={valueLocal}
              type="text"
              className="input__text"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
