import { evalue } from "../../../../ValueCheck/helper/onChangeHelper.helper";

export default function selectedToInput(
  selectedValues: Array<string>,
  query: evalue[]
) {
  let final: any = "";
  let idArr: any = [];
  selectedValues.map((value: any) => {
    const current: any = query[value];
    const prefix = final ? "&" : "?";
    const name = current["name"] || "";
    const values = current["value"] || "";

    final += `${prefix}${name}=${values}`;
    idArr.push(value);
  });
  return { final, idArr };
}
