import axiosInstenceAuth from "../axios/authAxios";
import axiosInstenceGuest from "../axios/guestAxios";

export async function refresh() {
  try {
    const { data } = await axiosInstenceGuest.get("/authorization/refresh");
    if (data.error) {
      localStorage.removeItem("token");
    }
    return data;
  } catch (error: any) {
    throw new Error(error.message);
  }
}
export async function userData(userID: string | null = null) {
  const { data } = await axiosInstenceAuth.get(
    `/authorization/user/?id=${userID}`,
    { withCredentials: true }
  );
  return data;
}
export async function createLink(email: string, url: string) {
  const { data } = await axiosInstenceGuest.post(
    url,
    { email },
    { withCredentials: true }
  );
  return data;
}
export async function resetPassword(password: string) {
  const { data } = await axiosInstenceAuth.post(
    "/authorization/changePassowrd",
    { password },
    { withCredentials: true }
  );
  return data;
}
export async function confirmLink(link: string, isResetLink: boolean) {
  const subDomen = isResetLink ? "reset" : "link";
  const { data } = await axiosInstenceAuth.get(
    `/authorization/${subDomen}/${link}`,
    {
      withCredentials: true,
    }
  );
  return data;
}
export async function createAccount(
  login: string,
  password: string,
  email: string
): Promise<any> {
  const { data } = await axiosInstenceAuth.post(
    "/authorization/createAccount",
    {
      login,
      password,
      email,
    },
    { withCredentials: true }
  );
  return data;
}
export async function logout() {
  const { data } = await axiosInstenceGuest.get("/authorization/logout", {
    withCredentials: true,
  });
  if (data.error) {
    alert("something goes wrong...");
  }
  localStorage.removeItem("token");
  return (window.location.pathname = "/");
}
export async function login(login: string, password: string) {
  const { data } = await axiosInstenceGuest.post(
    "/authorization/login",
    { login, password },
    { withCredentials: true }
  );
  return data;
}
