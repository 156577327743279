import { useEffect } from "react";

let ids: any = null;
export default function useErrorDisapear(ifError: boolean, dispatch: any) {
  useEffect(() => {
    if (ifError) {
      __dispatchDisapear(dispatch);
    }
  }, [ifError]);
}
function __dispatchDisapear(dispatch: any) {
  if (ids) {
    clearTimeout(ids);
  }
  ids = setTimeout(() => {
    dispatch({
      type: "",
      payload: { error: { text: null, type: 'x', } },
    });
  }, 4000);
}
