import { useEffect, useState } from "react";

export default function useIsShadowHook(shadow: any) {
  const [isShadow, setIsShadow] = useState<boolean>(false);
  useEffect(() => {
    if (shadow) {
      const keys = Object.keys(shadow);
      let exit = false;
      keys.map((prop) => {
        const current: any = shadow[prop as keyof Object];
        const newDATA = Object.values(current);
        if ((current || newDATA.includes(true)) && !exit) {
          exit = true;
        }
      });
      setIsShadow(exit);
    }
  }, [shadow]);
  return { isShadow };
}
