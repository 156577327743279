import { useState, ChangeEventHandler, ChangeEvent, useRef } from "react";
import ifEventChecked from "./ifEventChecked.helper";

interface IChangeHelper {
  stateClicked: boolean;
  className: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  name: string | undefined;
  value: string | undefined;
}
type CustomChangeEvent = ChangeEvent<HTMLInputElement> & { value: Object };
export default function OnChangeHelper({
  stateClicked,
  className,
  onChange,
  name,
  value,
}: IChangeHelper) {
  const [nameLocal, setName] = useState<string>("");
  const [valueLocal, setValue] = useState<string>("");

  let localName: string;
  let localValue: string;
  let localClicked: boolean;
  let localClassName: string;

  const _ifOnChageExist = (e: CustomChangeEvent) => {
    if (onChange) {
      const nameValueInput = {
        name: localName,
        value: localValue,
        isClicked: localClicked,
        className: localClassName,
      };
      e.value = nameValueInput;

      onChange(e);
    }
  };
  const onChangeInput = (e: CustomChangeEvent) => {
    const currentValue = e.target.value;
    localName = nameLocal ? nameLocal : name || "";
    localValue = valueLocal ? valueLocal : value || "";
    localClicked = stateClicked;
    localClassName = className;
    switch (e.target.name) {
      case "name":
        setName(currentValue);
        localName = currentValue;
        break;
      case "value":
        setValue(currentValue);
        localValue = currentValue;
        break;
      case "checkbox":
        localClicked = e.target.checked;
        localClassName = ifEventChecked(localClicked);
        break;
    }
    _ifOnChageExist(e);
  };
  return { onChangeInput, nameLocal, valueLocal, setName, setValue };
}
