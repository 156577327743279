import { useEffect } from "react";
//@ts-ignore
import Prism from "prismjs";
//@ts-ignore
import "prismjs/components/prism-json";
import "prismjs/themes/prism.css";
export default function Code({
  codeREF,
  content,
  type,
}: {
  codeREF: any;
  type: string;
  content: any;
}) {
  useEffect(() => {
    if (codeREF.current && content) {
      Prism.highlightElement(codeREF.current);
    }
  }, [content]);
  return (
    <code
      className="language-json"
      ref={codeREF}
      style={{
        overflowY: "auto",
        maxHeight: "100vh",
        whiteSpace:
          type !== "json" && type !== "reactNode" ? "break-spaces" : undefined,
      }}
    >
      {content}
    </code>
  );
}
