import "./NotAuthorizated.style.scss";
import { useNavigate } from "react-router-dom";
import ButtonModule from "../ButtonForShadows/ButtonModule";

function NotAuthorizated({
  shadow,
  setState,
}: {
  shadow?: any;
  setState?: any;
}) {
  const redirect = useNavigate();

  const onClick = (url: string) => {
    redirect(url);
    setState({
      type: "shadow",
      payload: { ...shadow, isUploadClicked: false },
    });
  };
  return (
    <div className="request">
      <div className="request__header"></div>
      <div className="request__buttonBlock">
        <ButtonModule
          onClick={() => onClick("/authorization/signup")}
          className="request__btn"
        >
          Sign-up
        </ButtonModule>
        <ButtonModule
          backgroundColor={"gray"}
          color={"white"}
          onClick={() => onClick("/authorization/signin")}
          className="request__btn"
        >
          Sign-in
        </ButtonModule>
      </div>
    </div>
  );
}
export default NotAuthorizated;
