import { FormEvent, useContext, useState } from "react";
import { clientContext } from "../../../../contextAPI";
import { contextValue } from "../../../../contextAPI/contex.interface";
import useWhenSubmited from "./hooks/useWhenSubmite.hook";
import useWhenQuery from "./hooks/useWhenQuery.hook";
import onChangeHelper from "./helper/onChange.helper";
import onSubmitHelper from "./helper/onSubmit.helper";

export default function InputHelper() {
  const [inputState, setInputState] = useState<string>("");
  const [inputWithId, setInputWithID] = useState<string[]>([""]);
  const { setState, state } = useContext<contextValue>(clientContext);
  const { query } = state;
  const WhenSubmitedobj = {
    inputState,
    setState,
    state,
  };
  useWhenSubmited(WhenSubmitedobj);
  useWhenQuery({ query, inputState, setInputWithID, setInputState });

  const onChangeHelperObj = {
    setInputState,
    setState,
    inputWithId,
    isOptions: state.isOptions,
    query
  };
  const { onChange } = onChangeHelper(onChangeHelperObj);
  const { onSubmit } = onSubmitHelper({ inputState, setState });
  return { onSubmit, setState, inputState, onChange };
}
