import { useEffect, useState } from "react";
import ReturnThemeHelper from "../../../../../helper/returnThemeChange.helper";
import Check from "./Check.module";
import CheckBlockHelper from "./CheckBlock.helper";
import "./CheckBlock.style.scss";
import MethodChoiseModule from "../../../Search/Input/MethodChoise.module";

function CheckBlock() {
  const { onClick, isMobile, setState } = CheckBlockHelper();
  const { isFullScreen, theme, inRefresh } = ReturnThemeHelper();
  return (
    <div className="checkBlock">
      <div className="checkBlock__inner">
        <div className="checkBlock__direction">
          {isMobile ? <MethodChoiseModule setState={setState}/> : null}
          <Check
            style={{ marginTop: "15px" }}
            id={"refresh"}
            turn={inRefresh}
            onClick={onClick}
            text="result with refresh"
          />
          <Check
            style={{ marginTop: "15px" }}
            id={"full-display"}
            turn={isFullScreen}
            onClick={onClick}
            text="full-screen data"
          />
          <Check
            style={{ marginTop: "15px" }}
            id={"theme"}
            onClick={onClick}
            turn={theme}
            text={theme ? "Light Mode" : "Dark Mode"}
          />
        </div>
      </div>
    </div>
  );
}
export default CheckBlock;
