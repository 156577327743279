import {
  ChangeEvent,
  ChangeEventHandler,
  useRef,
  MutableRefObject,
  useContext,
  MouseEventHandler,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { clientContext } from "../../contextAPI";
import { contextValue } from "../../contextAPI/contex.interface";
import { uploadImage } from "../../restAPI/api/personalAPI";
import {
  _NavigateToError,
  _setUserAndNavigate,
} from "../../helper/tokenSave.helper";
import { useNavigate } from "react-router-dom";
import SetBackground from "../../helper/setBackground.helper";

interface IReturnValue {
  inputRef: MutableRefObject<HTMLInputElement>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  inputClick: MouseEventHandler<HTMLButtonElement>;
  emailOpen: boolean;
  setEmailOpen: Dispatch<SetStateAction<boolean>>;
}
export default function SuggestHelper(): IReturnValue {
  const [emailOpen, setEmailOpen] = useState<boolean>(false);
  const {
    state: {
      user: { isLoggined },
      shadow,
    },
    setState,
  } = useContext<contextValue>(clientContext);
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null!);
  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setState({
      type: "shadow",
      payload: { isLoading: true },
    });
    const image = e.target.files;
    if (image && image[0] && image[0].type.includes("image")) {
      const data = await uploadImage(image[0]);
      _NavigateToError(data, navigate) ||
        _setUserAndNavigate(setState, null, data);

      if (data && !data.error) {
        const url = URL.createObjectURL(image[0]);
        SetBackground(url);
      }
    } else {
      _NavigateToError(
        {
          error:
            "The file type does not match or the image has not been provided",
        },
        navigate
      );
    }
    setState({ type: "shadow", payload: { ...shadow, isLoading: false } });
  };
  const inputClick = (e: any) => {
    if (isLoggined) {
      setState({
        type: "shadow",
        payload: {
          ...shadow,
          isSuggestion: {
            fun: inputRef.current,
          },
        },
      });
    } else {
      setState({
        type: "shadow",
        payload: { ...shadow, isUploadClicked: true },
      });
    }
  };
  return { inputRef, onChange, inputClick, emailOpen, setEmailOpen };
}
