import onChangeHelper from "./helper/onChangeHelper.helper";
import onClickHelper from "./helper/onClickHelper.helper";
import useCustomEffect from "./hooks/useCustomEffect.hook";
import InputModule from "../Content/Section/Form/Input/Input.module";
import { useContext, useState } from "react";
import { contextValue } from "../../contextAPI/contex.interface";
import { clientContext } from "../../contextAPI";

export default function ValueCheckService(name: string) {
  const { setState, state } = useContext<contextValue>(clientContext);
  const [oldState, setOldState] = useState<Object>({});
  const onChange = onChangeHelper({ setState, name, setOldState, state });
  function onClick(e: any) {
    const onClick = onClickHelper({ setInputs, onChange });
    return onClick(e);
  }
  const [inputs, setInputs] = useState([
    <InputModule key={0} id={0} onChange={onChange} onClick={onClick} />,
  ]);
  useCustomEffect({ setInputs, state, onChange, onClick, name, oldState });
  return { inputs };
}
