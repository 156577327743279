import sendler from "../axios/guestAxios";

export async function sendRequest({
  url,
  method,
  body,
  headers,
  isOptions,
}: {
  url: string;
  method: string;
  body: JSON | null;
  headers: Object | null;
  isOptions: boolean;
}) {
  let urlObj = null;
  try {
    if (!isOptions) {
      url = url?.split("?")[0];
      body = null;
      headers = null;
    }
    urlObj = new URL(url);
  } catch (error: any) {
    return error.message;
  }
  const host = urlObj.hostname === "localhost";
  if (!host) {
    return await sendToAPI(url, method, body, headers);
  } else {
    const isInstalled = await connectToExtension();
    return isInstalled
      ? (await sendRequestToLocal(url, headers, body, method)) ||
          "Something goes wrong"
      : {
          info: "Website can not send request to API that on local machine.",
          solution:
            "Extension can send a request that on local machine, install an extension for sending request to API that on local machine",
          extension:
            "https://chrome.google.com/webstore/detail/checkapiapp/iandjldogjdmkbllibghhejgcafnjlpm?hl=ru&authuser=3",
        };
  }
}
async function sendToAPI(
  url: string,
  method: string,
  body: JSON | null,
  headers: Object | null
) {
  const { data } = await sendler.post(
    `${process.env.REACT_APP_SERVER_URL}/api/checkApi/default/sendRequest?requestURL=${url}&method=${method}`,
    { data: { body, headers } }
  );
  return data;
}

async function sendRequestToLocal(
  url: string,
  headers: Object | null,
  body: JSON | null,
  method: string
) {
  return await new Promise((resolve, reject) => {
    try {
      //@ts-ignore
      chrome.runtime.sendMessage(
        process.env.REACT_APP_EXTENSION_CODE,
        { message: { url, headers, body, method } },
        (response: any) => responseFromExtension(resolve, response)
      );
    } catch (error) {
      resolve(error);
    }
  });
}

function responseFromExtension(resolve: any, response: any) {
  let result = null;
  if (response.data) {
    const typedArray = new Uint8Array(response.data);
    result = { data: typedArray.buffer };
  } else {
    result = response.error;
  }
  resolve(result);
}

async function connectToExtension() {
  return await new Promise((resolve, reject) => {
    try {
      //@ts-ignore
      chrome.runtime.sendMessage(
        process.env.REACT_APP_EXTENSION_CODE,
        { type: "check" },
        function (response: any) {
          resolve(response.installed);
        }
      );
    } catch (error) {
      resolve(false);
    }
  });
}
