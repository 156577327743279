import { useContext, useEffect } from "react";
import ShadowContent from "./ShadowContent.module";
import { contextValue } from "../../../contextAPI/contex.interface";
import { clientContext } from "../../../contextAPI";

function ShadowModule() {
  const {state: {shadow: {isLoading}}} = useContext<contextValue>(clientContext);;
  useEffect(() => {
    const body = document.body.style;
    body.overflow = "hidden";
    return () => {
      body.overflow = "auto";
    };
  }, []);
  return (
    <>
      <div
        style={{
          zIndex: 99,
          position: "fixed",
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "black",
          opacity: isLoading ? '99%' : "40%",
        }}
      ></div>
      <ShadowContent />
    </>
  );
}
export default ShadowModule;
