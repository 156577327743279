import ReturnThemeHelper from '../../../helper/returnThemeChange.helper';
import InputModule from './Input/Input.module';
import './Search.style.scss';
function SearchModule() {
  const {backgroundColorInput} = ReturnThemeHelper();
  return (
    <section className="search">
      <div className="search__inner">
        <div className="search__direction">
            <h1 className="search__label" style={{color: backgroundColorInput}}>Check API</h1>
            <InputModule/>
        </div>
      </div>
    </section>
  );
}
export default SearchModule;
