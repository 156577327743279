import { MouseEventHandler, Dispatch, SetStateAction } from "react";
import ifEventChecked from "./ifEventChecked.helper";
interface IOnClickInputHelper {
  onClick?: MouseEventHandler<HTMLInputElement>;
  setStateClassName: Dispatch<SetStateAction<string>>;
  setStateClicked: Dispatch<SetStateAction<boolean>>;
  stateClicked: boolean;
}
export default function onClickInputHelper({
  onClick,
  setStateClassName,
  setStateClicked,
  stateClicked,
}: IOnClickInputHelper) {
  function onClickInput(e: any) {
    if (onClick) {
      onClick(e);
    }
    setStateClassName(ifEventChecked(e.target.checked));
    setStateClicked(!stateClicked);
  }
  return { onClickInput };
}
