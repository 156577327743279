import "./Loading.style.scss";
function LoadingModule() {
  return (
    <div className="lds-ring" style={{zIndex: 1000}}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
export default LoadingModule;
