import { useRef } from "react";
import { RouteStatic } from "./helper/routeFunctions";
import giveInputs from "./helper/giveInputs.helper";
import useLocalState from "./hooks/useLocalState.hook";
import useLocationChanged from "./hooks/useLocationChanged.hook";
import onSubmit from "./helper/onSubmit.helper";
import giveDto from "./helper/givesDto";
import useErrorDisapear from "./hooks/useErrorDisapear.hook";
import useReactError from "./hooks/useReactError.hook";
import useChangeText from "./hooks/useChangeText.hook";
import useRedirect from "./hooks/useRedirect.hook";
export enum ERROR_TYPE {
  LOGIN_OR_PASSWORD = 40001,
  ACC_NOT_FOUND = 40401,
  FILL_FIELDS,
  PASSWORD_ISNT_SAME = 40002,
  ACCOUNT_IS_TAKEN = 40901
}
export interface IInit {
  neededInputs: number;
  textToInputs: Array<string>;
  error: { text: string | null; type: ERROR_TYPE | null };
  suggestion: { text: string; content: JSX.Element } | null;
}
function AuthorizationVariantModule({
  text,
  setText,
}: {
  text: any;
  setText: Function;
}) {
  const locations = RouteStatic();
  const initialState: IInit = {
    neededInputs: 1,
    textToInputs: ["Email", "Passowrd"],
    error: { text: null, type: null },
    suggestion: null,
  };
  const { dataLocal, dispatch } = useLocalState(initialState);
  useLocationChanged(locations, initialState, dispatch);
  useChangeText(locations, text, setText);
  const emailRef = useRef<HTMLInputElement>(null!);
  const passwordRef = useRef<HTMLInputElement>(null!);
  const refs = { emailRef, passwordRef };
  const inputs = giveInputs(dataLocal, refs);
  const onClick: any = onSubmit(dispatch, dataLocal, locations, refs);
  const { ifSuggestion, ifError } = giveDto(dataLocal);
  useReactError(dataLocal, dispatch);
  useErrorDisapear(ifError, dispatch);
  useRedirect(locations);
  return (
    <>
      {dataLocal.neededInputs === 0 ? null : (
        <div className="inputBlock__direction">
          {inputs}
          <button className="inputBlock__button" onClick={onClick}>
            Enter
          </button>
          {ifError}
          {ifSuggestion}
        </div>
      )}
    </>
  );
}
export default AuthorizationVariantModule;
