import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function useLocationChanged(
  locations: any,
  initialState: Object,
  dispatch: Function
) {
  useEffect(() => {
    let type = "";
    let payload: any = initialState;
    payload = ifSignInOrCreate(locations, payload);
    payload = ifLogOutOrConfirm(locations, payload);
    payload = ifResetPassword(locations, payload);
    dispatch({ type: type, payload: payload });
  }, [JSON.stringify(locations)]);
}

function ifResetPassword(locations: any, payload: any) {
  if (locations.matchNewPassword) {
    payload = {
      neededInputs: 2,
      textToInputs: ["Password", "Confirm the Passowrd"],
    };
  }
  return payload;
}

function ifLogOutOrConfirm(locations: any, payload: any) {
  if (locations.matchlogout || locations.matchpleaseConfirm) {
    payload = { neededInputs: 0 };
  }
  return payload;
}

function ifSignInOrCreate(locations: any, payload: any) {
  if (locations.matchsignin || locations.matchcreateAccount) {
    payload = { neededInputs: 2, textToInputs: ["Login", "Password"] };
    if (locations.matchsignin) {
      const content = <Link to={"/authorization/signup"}>Create then</Link>;
      payload = {
        ...payload,
        suggestion: { text: "Don`t have an account?", content },
      };
    }
  }
  return payload;
}
