import CodeSnipet from "../../Content/Section/Display/CodeSnipet.module";
import DisplayModule from "../../Content/Section/Display/Display.module";
import "./FullContent.style.scss";

function FullcontentModule({
  shadow,
  setState,
}: {
  shadow: any;
  setState: any;
}) {
  const disableShadow = () => {
    setState({
      type: "shadow",
      payload: { ...shadow, isFullScreen: !shadow.isFullScreen },
    });
  };
  return shadow.isFullScreen ? (
    <div className="fullContent">
      <button onClick={disableShadow} style={{ cursor: "pointer" }}>
        X
      </button>
      <DisplayModule
        style={{ width: "95%", height: "100%", paddingRight: "15px" }}
      />
    </div>
  ) : null;
}
export default FullcontentModule;
