export default function SetBackground(image: string | null) {
  const element = document.getElementById("backgroundImage");
  if (element) {
    const style = element.style;
    if (image) {
      style.display = 'block';
      element.setAttribute('src', image);
      style.background = `url(${image})`;
      style.backgroundRepeat = "no-repeat";
      style.backgroundPosition = "center";
      style.backgroundSize = "cover";
    } else {
      style.background = "unset";
      style.display = 'none';
    }
  }
}
