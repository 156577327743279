export default function giveDto(dataLocal: any) {
  const ifError = dataLocal.error && (
    <p style={{ color: "red", marginTop: "15px" }}>{dataLocal.error.text}</p>
  ) || null;
  const ifSuggestion = dataLocal.suggestion && (
    <p className="suggest">
      {dataLocal.suggestion.text}
      {dataLocal.suggestion.content}
    </p>
  ) || null;
  return { ifError, ifSuggestion };
}
