import axios, {
  CreateAxiosDefaults,
  AxiosInstance,
  AxiosError,
} from "axios";
import { returnError, ifAccesTokenExist } from ".";

const config: CreateAxiosDefaults<any> = {
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api`,
  withCredentials: true,
};
const axiosInstenceAuth: AxiosInstance = axios.create(config);
axiosInstenceAuth.interceptors.request.use((request)=>{
  request.withCredentials = true;
  return request;
})
axiosInstenceAuth.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("token");
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosInstenceAuth.interceptors.response.use(
  (response) => {
    const decoded = ifAccesTokenExist(response);
    response.data = decoded || response.data;
    return response;
  },
  async (error: AxiosError<{status: number, response: string}>) => {
    if (error && error.response && error.response.status === 401) {
      const tempAxios = axios.create({
        baseURL: `${process.env.REACT_APP_SERVER_URL}/api`,
      });
      tempAxios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          localStorage.removeItem("token");
          return error;
        }
      );
      const refreshConfig = { ...error.config, withCredentials: true };
      const { data } = await tempAxios.get(
        "/authorization/refresh",
        refreshConfig
      );
      if (data) {
        localStorage.setItem("token", data.accessToken);
        return axiosInstenceAuth.request(refreshConfig);
      }
    }
    const customStatus = error.response?.data.status;
    if (customStatus && customStatus.toString().length > 4) {
      return { data: { error: error.response?.data } };
    }
    return { data: returnError(error) };
  }
);
export default axiosInstenceAuth;
