import { useEffect, useState } from "react";
import CodeSnipet from "./CodeSnipet.module";
import "./Display.style.scss";
function BorderModule({
  displayData,
  type = "reactNode",
  noSinpet = false,
}: {
  displayData: any;
  type?: string;
  noSinpet?: boolean;
}) {
  const [content, setContent] = useState<any>(null);
  useEffect(() => {
    if (displayData && type !== "json" && type !== "reactNode") {
      const img = document.createElement("img");
      const video = document.createElement("video");
      img.src = displayData;
      video.src = displayData;
      img.addEventListener("error", () => {
        setContent(<video src={displayData} controls />);
      });
      video.addEventListener("error", () => {
        setContent(<img src={displayData} />);
      });
    } else {
      setContent(displayData);
    }
  }, [displayData]);
  return (
    <div className="display__inner" style={{ height: "inherit" }}>
      <div className="display__direction" style={{ height: "inherit" }}>
        {noSinpet ? (
          content
        ) : (
          <CodeSnipet displayData={displayData} type={type} />
        )}
      </div>
    </div>
  );
}
export default BorderModule;
