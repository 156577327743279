import { ChangeEvent, ChangeEventHandler, useContext, useState } from "react";
import { clientContext } from "../../../../../contextAPI";
import { contextValue } from "../../../../../contextAPI/contex.interface";

interface IreturnedValue {
  onClick: ChangeEventHandler<HTMLInputElement>;
  isMobile: boolean;
  setState: any;
}
export default function CheckBlockHelper(): IreturnedValue {
  const {
    setState,
    state: { shadow },
  } = useContext<contextValue>(clientContext);
  const onClick = (e: ChangeEvent<HTMLInputElement>) => {
    switch (e.target.id) {
      case "refresh":
        {
          setState({ type: "inRefresh", payload: null });
        }
        break;
      case "full-display":
        {
          setState({
            type: "shadow",
            payload: { ...shadow, isFullScreen: !shadow.isFullScreen },
          });
        }
        break;
      case "theme":
        {
          setState({ type: "themeChange", payload: null });
        }
        break;
    }
  };
  const checkIsMob = () => {
    return window.innerWidth <= 576 ? true : false;
  };
  const [isMobile, setISMobile] = useState<boolean>(checkIsMob());
  window.addEventListener("resize", () => {
    setISMobile(checkIsMob());
  });
  return { onClick, isMobile, setState };
}
