import { CSSProperties } from "react";

import "./Display.style.scss";
import BorderModule from "./Border.module";
import DisplayHelper from "./Display.helper";

function DisplayModule({ style = null }: { style?: CSSProperties | null }) {
  const { displayData, type } = DisplayHelper();
  return (
    <div className="display" style={style || undefined}>
      <BorderModule
        displayData={displayData}
        noSinpet={type !== "json"}
        type={type}
      />
    </div>
  );
}
export default DisplayModule;
