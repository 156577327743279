/* eslint-disable */
import jwtDecode from "jwt-decode";
import {
  _NavigateToError,
  _setUserAndNavigate,
} from "../../../helper/tokenSave.helper";
import {
  login,
  createLink,
  createAccount,
  resetPassword,
} from "../../../restAPI/api/userAPI";
import { ERROR_TYPE } from "../AuthorizationVariant.module";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { clientContext } from "../../../contextAPI";
import { SetState, contextValue } from "../../../contextAPI/contex.interface";

let values: any = [];
let isErorr = false;
let errorData = {};
export default function onSubmit(
  dispatch: Function,
  dataLocal: any,
  locations: any,
  ref: { emailRef: any; passwordRef: any }
) {
  const {
    state: { shadow },
    setState,
  } = useContext<contextValue>(clientContext);
  const navigate = useNavigate();
  return async function onSubmit() {
    values = [];
    isErorr = false;
    errorData = {};

    setState({ type: "shadow", payload: { ...shadow, isLoading: true } });
    __filterInputs(locations, dataLocal, ref);
    if (isErorr) {
      return __giveAnError(setState, shadow, dispatch);
    }
    const pathController = { locations, navigate };
    const obj: any = await __sendTo(dispatch, pathController);
    setState({ type: "shadow", payload: { ...shadow, isLoading: false } });
    _setUserAndNavigate(setState, navigate, obj.data, obj.locationTo);
  };
}

function __filterInputs(
  locations: any,
  dataLocal: any,
  ref: { emailRef: any; passwordRef: any }
) {
  const ifNotAllField = !__isAllFilled(dataLocal, ref);
  if (ifNotAllField) {
    isErorr = true;
    errorData = {
      error: {
        text: "Please fill in all fields",
        type: ERROR_TYPE.FILL_FIELDS,
      },
    };
  }
  const ifResetThenMatch =
    locations.matchNewPassword && values[0] !== values[1];
  if (ifResetThenMatch) {
    isErorr = true;
    errorData = {
      error: {
        text: "Passwords must match",
        type: ERROR_TYPE.PASSWORD_ISNT_SAME,
      },
    };
  }
}

async function __sendTo(
  dispatch: Function,
  pathController: { locations: any; navigate: any }
) {
  const { locations, navigate } = pathController;
  let { data, locationTo } = await __sendFetch(locations);
  if (data.error) {
    __ifErrorExist(data, dispatch, navigate);
  } else {
    return { data, locationTo };
  }
}

function __ifErrorExist(data: any, dispatch: Function, navigate: any) {
  let errorType = __givesErrorType(data);
  if (errorType) {
    dispatch({
      type: "",
      payload: {
        error: {
          text: data.error.error,
          type: errorType,
        },
      },
    });
  } else {
    _NavigateToError(data, navigate);
  }
}

function __givesErrorType(data: any) {
  let localErrorType = null;
  switch (data.error.status) {
    case 40001:
      localErrorType = ERROR_TYPE.LOGIN_OR_PASSWORD;
      break;
    case 40401:
      localErrorType = ERROR_TYPE.ACC_NOT_FOUND;
      break;
    case 40002:
      localErrorType = ERROR_TYPE.PASSWORD_ISNT_SAME;
      break;
    case 40901:
      localErrorType = ERROR_TYPE.ACCOUNT_IS_TAKEN;
  }
  return localErrorType;
}

async function __sendFetch(locations: any) {
  let localData = null;
  let locationTo = "/";
  if (locations.matchsignin) {
    localData = await login(values[0], values[1]);
  }
  if (locations.matchsignup) {
    localData = await createLink(values[0], "/authorization/createConfirmLink");
    locationTo = "/authorization/pleaseConfirm";
  }
  if (locations.matchNewPassword) {
    localData = await resetPassword(values[0]);
  }
  if (locations.matchcreateAccount) {
    const decoded: any = jwtDecode(localStorage.getItem("token") || "");
    localData = await createAccount(values[0], values[1], decoded.email);
  }
  if (locations.matchResetPassword) {
    localData = await createLink(values[0], "/authorization/createResetLink");
    locationTo = "/authorization/pleaseConfirm";
  }
  return { data: localData, locationTo };
}

function __giveAnError(setState: SetState, shadow: any, dispatch: Function) {
  setState({ type: "shadow", payload: { ...shadow, isLoading: false } });
  return dispatch({
    type: "",
    payload: errorData,
  });
}

function __isAllFilled(
  dataLocal: any,
  refs: { emailRef: any; passwordRef: any }
) {
  let LocalBoolean = true;
  const checkInputs = [refs.emailRef.current, refs.passwordRef.current];
  for (let index = 0; index < dataLocal.neededInputs; index++) {
    const current = checkInputs[index];
    if (current && current.value.length <= 0) {
      LocalBoolean = false;
    } else {
      values.push(checkInputs[index].value);
    }
  }
  return LocalBoolean;
}
