import "./Suggest.style.scss";
import SuggestHelper from "./Suggest.helper";
import ReturnThemeHelper from "../../helper/returnThemeChange.helper";
import { Link } from "react-router-dom";
function SuggestModule() {
  const { inputRef, onChange, inputClick, emailOpen, setEmailOpen } =
    SuggestHelper();
  const { backgroundColorInput, textColor } = ReturnThemeHelper();
  return (
    <footer className="suggestion">
      <div className="suggestion__inner">
        <div className="suggestion__direction">
          <p
            className="suggestion__text"
            style={{ color: backgroundColorInput }}
          >
            You can also change the background of the site by adding an image
          </p>
          <input
            hidden
            type="file"
            accept="image/*"
            ref={inputRef}
            onChange={onChange}
          />
          <button
            onClick={inputClick}
            style={{ background: backgroundColorInput, color: textColor }}
            className="suggestion__btn"
          >
            Upload
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </button>
          <div className="suggestion__imgs">
            <img
              title="You can support me by paying part of the server fee"
              alt="icone with ko-fi image, also work as a button, will redirect to ko-fi site"
              onClick={() =>
                window.open("https://ko-fi.com/chocodev", "_blank")
              }
              src="./ko-fi.png"
              className="suggestion__donate__btn"
              width={30}
              height={"auto"}
            />
            <img
              onClick={() => setEmailOpen(!emailOpen)}
              title="You can email me if there are any problem"
              src="./gmail.png"
              alt="email"
              width={30}
              height={"auto"}
            />
            {emailOpen ? (
              <input
                className="email"
                readOnly={true}
                type="email"
                defaultValue={"chocoantirus@gmail.com"}
              />
            ) : null}
          </div>
        </div>
      </div>
      <Link
        style={{ color: backgroundColorInput }}
        to={"/privacy"}
        id="privacy"
      >
        privacy policy
      </Link>
    </footer>
  );
}
export default SuggestModule;
