import { useState } from "react";

export default function useIsMoreThen(num: number){
    const checkIsMore = () => {
        return window.innerWidth <= num ? false : true;
      };
      const [isMore, setIsMore] = useState<boolean>(checkIsMore());
      window.addEventListener("resize", () => {
        setIsMore(checkIsMore());
      });
      return isMore;
}