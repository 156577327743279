import './Options.style.scss';
import CheckBlock from './Check/CheckBlock.module';
function Options() {  
  return (
    <div className="options">
      <div className="options__inner">
        <div className="options__direction">
          <CheckBlock/>
        </div>
      </div>
    </div>
  );
}
export default Options;
