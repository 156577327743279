import { useContext, useEffect, useState } from "react";
import { contextValue } from "../../../../../contextAPI/contex.interface";
import { clientContext } from "../../../../../contextAPI";

export default function BodyHelper() {
  const defaultValue = "{\n\n}";
  const [text, setText] = useState<string>(defaultValue);
  const {
    state: {
      contentData: { isSubmited },
      body,
    },
    setState,
  } = useContext<contextValue>(clientContext);
  useEffect(() => {
    if (isSubmited) {
      setState({ type: "body", payload: text });
      setState({
        type: "contentData",
        payload: { data: null, isSubmited: false },
      });
    }
  }, [isSubmited]);
  useEffect(() => {
    setTimeout(() => {
      setState({
        type: "body",
        payload: text.length > 0 ? text : defaultValue,
      });
    }, 500);
  }, [text]);
  useEffect(() => {
    if (body) {
      setText(body.toString());
    }
  }, []);
  return { text, setText };
}
