import { RouteObject } from "react-router-dom";
import App from "../Pages/App/App.module";
import Authorization from "../Pages/Authorization/Authorization";
import ErrorPage from "../Pages/Error/Error.page";
import Index from "../Pages/Link/Index";
import HeaderWrapper from "../Wrappers/Header/Header";
import PrivacyModule from "../Pages/Privacy/Privacy.module";

export const Routers: RouteObject[] = [
  {
    path: "/error",
    element: <ErrorPage />,
  },
  {
    errorElement: <ErrorPage />,
    element: <HeaderWrapper />,

    children: [
      {
        path: "/",
        element: <App />,
      },
      {
        path: "/privacy",
        element: <PrivacyModule />,
      },
      {
        path: "link/:link",
        element: <Index />,
      },
      {
        path: "resetLink/:link",
        element: <Index />,
      },
      {
        path: "authorization",
        element: <Authorization />,
        children: [
          { path: "signin" },
          { path: "signup" },
          { path: "pleaseConfirm" },
          { path: "createAccount" },
          { path: "resetPassword" },
          { path: "newPassword" },
          {
            path: "logout",
            element: <div>Please wait...</div>,
            id: "logout",
            loader: () => {
              return true;
            },
          },
        ],
      },
    ],
  },
];
