export default function onClick(state: any, setState: any, navigate: any) {
  if (state.user.isLoggined) {
    const fn = (e: any, answer: boolean) => {
      if (answer) {
        navigate("/authorization/logout");
      }
    };
    const mainText = "Are you sure you want to log out of your account?";
    setState({
      type: "shadow",
      payload: {
        ...state.shadow,
        isNotification: { mainText, fn },
      },
    });
  } else {
    navigate("/authorization/signin");
  }
}
