import InputHelper from "./Input.helper";
import "./Input.style.scss";
import MethodChoiseModule from "./MethodChoise.module";
function InputModule() {
  const { onSubmit, setState, inputState, onChange } = InputHelper();
  return (
    <div className="input">
      <div className="input__inner">
        <div className="input__direction">
          <div className="input__button" onClick={onSubmit}>
            <img
              src="search.png"
              alt="search icon, existing in element that is a button itself."
              width={53}
              height={53}
            />
          </div>
          <MethodChoiseModule setState={setState} />
          <input
            aria-label="Search input"
            value={inputState}
            onChange={onChange}
            type="text"
            className="input__input"
          />
        </div>
      </div>
    </div>
  );
}
export default InputModule;
