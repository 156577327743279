import "./App.style.scss";
import ContentModule from "../../Components/Content/Content.module";
import SuggestModule from "../../Components/Suggest/Suggest.module";
import { Helmet } from "react-helmet-async";
function App() {
  return (
    <main className="main">
      <Helmet>
        <title>Check API</title>
        <meta
          name="description"
          content="Check API - Website to check the data from the API response. The site will help control and test your back-end"
        />
        <link rel="canonical" href={`https://checkapi.app/`}/>
      </Helmet>
      <div className="main__inner">
        <div className="main__direction">
          <ContentModule />
          <SuggestModule />
        </div>
      </div>
    </main>
  );
}
export default App;
