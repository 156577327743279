import { useContext, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { clientContext } from "../../contextAPI";
import { contextValue } from "../../contextAPI/contex.interface";
import ErrorHandlerHelper from "../../helper/errorHandler.helper";
import { confirmLink } from "../../restAPI/api/userAPI";

function Index() {
  const {
    setState,
    state: { shadow },
  } = useContext<contextValue>(clientContext);
  const navigate = useNavigate();
  const { link } = useParams();
  const locations = useLocation();
  const isResetLink = locations.pathname.split("/")[1] === "resetLink";
  const isConfirmLink = locations.pathname.split("/")[1] === "link";
  useEffect(() => {
    const url = isConfirmLink
      ? "/authorization/createAccount"
      : isResetLink
      ? "/authorization/newPassword"
      : null;
    setState({ type: "shadow", payload: { ...shadow, isLoading: true } });
    if (url) {
      (async () => {
        //@ts-ignore
        const data = await confirmLink(link, isResetLink);
        if (data.error) {
          ErrorHandlerHelper('Your link has expired or an error occurred Possible solutions: Allow cookies, exit incognito mode, or click the link again.', navigate);
        } else {
          navigate(url);
        }
      })();
    }
    setState({ type: "shadow", payload: { ...shadow, isLoading: false } });
  }, []);
  return <main>Please wait...</main>;
}
export default Index;
