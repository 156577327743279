import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { SetState } from "../../../../../contextAPI/contex.interface";
import giveQueryArr from "./giveQueryArr.helper";
import giveLongerArr from "./giveLongestArr.helper";
import changeQuerryRealTime from "./changeQueryRealTime.helper";

interface IOnChangeHelper {
  setInputState: Dispatch<SetStateAction<string>>;
  inputWithId: Array<string>;
  setState: SetState;
  isOptions: boolean;
}
export default function onChangeHelper({
  setInputState,
  setState,
  isOptions,
  inputWithId,
}: IOnChangeHelper) {
  function _setIntoState(e: ChangeEvent<HTMLInputElement>) {
    const value: string = e.target.value;
    setInputState(value);
  }
  function _updateInputText(value: string) {
    if (value.includes("?")) {
      const {inputQuerysArr, onlyKeys, onlyValues} = giveQueryArr(value);
      const theLongestArr = giveLongerArr(inputWithId, inputQuerysArr);
      changeQuerryRealTime({theLongestArr, onlyKeys, onlyValues, inputWithId, setState});
      if((inputQuerysArr.length > 0 || inputWithId.length > 0) && !isOptions)
      setState({ type: "isOptions", payload: null });
    }
  }
  // main logic
  function onChange(e: ChangeEvent<HTMLInputElement>) {
    _setIntoState(e);
    _updateInputText(e.target.value);
  }
  return { onChange };
}
