import {
  useState,
  ChangeEventHandler,
  MouseEventHandler,
  useEffect,
} from "react";
import onChangeHelper from "./helper/onChangeInput.helper";
import onClickInputHelper from "./helper/onClickInput";

interface IInputHelper {
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onClick?: MouseEventHandler<HTMLInputElement>;
  className: string;
  isClicked: boolean;
  name: string | undefined;
  value: string | undefined;
}
export default function InputHelper({
  onChange,
  onClick,
  className,
  isClicked,
  name,
  value,
}: IInputHelper) {
  const [stateClicked, setStateClicked] = useState<boolean>(isClicked);
  const [stateClassName, setStateClassName] = useState<string>(className);

  useEffect(() => {
    setStateClassName(className);
    setStateClicked(isClicked);
  }, [className, isClicked]);
  const { nameLocal, valueLocal, onChangeInput, setName,setValue } = onChangeHelper({
    name,
    value,
    onChange,
    className,
    stateClicked,
  });
  const onClickInputObj = {
    stateClicked,
    setStateClassName,
    setStateClicked,
    onClick,
  };
  const { onClickInput } = onClickInputHelper(onClickInputObj);

  return {
    nameLocal,
    valueLocal,
    onChangeInput,
    onClickInput,
    stateClassName,
    stateClicked,
    setName,
    setValue
  };
}
