import axios, { AxiosError, AxiosInstance, CreateAxiosDefaults } from "axios";
import { returnError, ifAccesTokenExist } from ".";
const configSendler: CreateAxiosDefaults<any> = {
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api/`,
};
const axiosInstenceGuest: AxiosInstance = axios.create(configSendler);
axiosInstenceGuest.interceptors.request.use((request) => {
  request.withCredentials = true;
  return request;
});
axiosInstenceGuest.interceptors.response.use(
  (response) => {
    const decoded = ifAccesTokenExist(response);
    response.data = decoded || response.data;
    return response;
  },
  (err: AxiosError<{ status: number; response: string }>) => {
    const customStatus = err.response?.data.status;
    if (customStatus && customStatus.toString().length > 4) {
      return { data: { error: err.response?.data } };
    }
    return { data: returnError(err) };
  }
);
export default axiosInstenceGuest;
