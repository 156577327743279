export const mainReducer = (
  state: any,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case "themeChange": {
      localStorage.setItem("theme", JSON.stringify(!state.theme));
      return Object.assign({}, state, { theme: !state.theme });
    }
    case "contentData":
      return Object.assign({}, state, {
        contentData: {
          data: action.payload.data,
          isSubmited: action.payload.isSubmited,
        },
      });
    case "inRefresh": {
      localStorage.setItem("inRefresh", JSON.stringify(!state.inRefresh));
      return Object.assign({}, state, { inRefresh: !state.inRefresh });
    }
    case "shadow":
      return Object.assign({}, state, { shadow: action.payload });
    case "method":
      return Object.assign({}, state, { method: action.payload });
    case "isOptions":
      return Object.assign({}, state, { isOptions: !state.isOptions });
    case "body":
      return Object.assign({}, state, { body: action.payload });
    case "headers":
      return Object.assign({}, state, {
        headers: setBlockInputs(state, action.payload, "headers"),
      });
    case "query":
      return Object.assign({}, state, {
        query: setBlockInputs(state, action.payload, "query"),
      });
    case "user":
      return Object.assign({}, state, {
        user: {
          data: action.payload.data,
          isLoggined: action.payload.isLoggined,
          isTheme: action.payload.isTheme,
        },
      });
  }
};
const setBlockInputs = (state: any, payload: any, name: string) => {
  const id = Object.keys(payload)[0];
  const valueObj = payload[id];
  let oldArr: Object = state[name];
  let idNode = oldArr[id as keyof Object];
  oldArr[id as keyof Object] = idNode ? { ...idNode, ...valueObj } : valueObj;
  return oldArr;
};
