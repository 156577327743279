import ErrorHandlerHelper from "./errorHandler.helper";
import ifActivatedSaveUser from "./ifActivatedSave.helper";

export function _setUserAndNavigate(
  setState: Function,
  navigate: Function | null,
  data: any,
  url: string = "/"
) {
  ifActivatedSaveUser(data, setState);
  if (navigate) {
    navigate(url);
  }
}
export function _NavigateToError(data: any, navigate: Function) {
  return data.error && ErrorHandlerHelper(data.error, navigate);
}
