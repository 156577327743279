export default function ifActivatedSaveUser(
  data: any,
  setState: Function,
) {
  if (data && !data.error) {
    setState({
      type: "user",
      payload: {
        data: data,
        isLoggined: data.isActivated && !data.token.confirmLink && data.login,
      },
    });
  }
}
