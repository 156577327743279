import { useContext, useEffect, useState, CSSProperties } from "react";
import { clientContext } from "../../../../contextAPI";
import { contextValue } from "../../../../contextAPI/contex.interface";

function containsBinaryData(str: string) {
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    if (charCode < 32 || charCode > 126) {
      return true;
    }
  }
  return false;
}
export const makeInto = async (data: { data: ArrayBuffer }) => {
  const uint8Array = new Uint8Array(data.data);
  const blob = new Blob([uint8Array]);
  const innerBufferData = await blob.text();
  const isNotJson = containsBinaryData(JSON.stringify(innerBufferData));
  return {
    filtredData: isNotJson
      ? URL.createObjectURL(blob)
      : JSON.stringify(JSON.parse(innerBufferData), null, 4),
    filteredType: isNotJson ? "blob" : "json",
  };
};
export default function DisplayHelper() {
  const {
    state: {
      contentData: { data },
    },
  } = useContext<contextValue>(clientContext);
  const [displayData, setDisplayData] = useState<any>(null);
  const [type, setType] = useState<string>("json");
  useEffect(() => {
    if (data) {
      if (data.data) {
        (async () => {
          const { filtredData, filteredType } = await makeInto(data);
          setDisplayData(filtredData);
          setType(filteredType);
        })();
      } else {
        setDisplayData(JSON.stringify(data, null, 4));
        setType("json");
      }
    }
  }, [data]);
  useEffect(() => {
    const response: any = localStorage.getItem("responseData");
    let filtredData, filteredType: any;
    if (response) {
      const obj = JSON.parse(response);
      if (!obj.error) {
        (async () => {
          ({ filtredData, filteredType } = await makeInto(obj));
          setType(filteredType);
          setDisplayData(filtredData);
        })();
      } else {
        filteredType = "json";
        filtredData = obj.error;
        setType(filteredType);
        setDisplayData(filtredData);
      }
      localStorage.removeItem("responseData");
    }
  }, []);
  return { displayData, type };
}
