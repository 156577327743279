import { useContext, useState, useEffect } from "react";
import { clientContext } from "../../../../contextAPI";
import { contextValue } from "../../../../contextAPI/contex.interface";
import CheckModule from "../Options/Check/Check.module";
import BorderModule from "../Display/Border.module";
import "./Form.style.scss";
import BodyModule from "./Body/Body.module";
import ValueCheckModule from "../../../ValueCheck/ValueCheck.module";

function FormModule() {
  const {
    state: { method, isOptions, query },
    setState,
  } = useContext<contextValue>(clientContext);
  const [togle, setTogle] = useState<string>(undefined!);
  const onClick = () => {
    setState({ type: "isOptions", payload: null });
  };
  useEffect(() => {
    if (
      method === "get" ||
      method === "head" ||
      method === "options" ||
      method === "delete"
    ) {
      setTogle(undefined!);
    }
  }, [method]);
  useEffect(() => {
    setTogle(Object.keys(query).length > 0 ? "query" : undefined!);
  }, [JSON.stringify(query)]);
  return (
    <div className="form">
      <div className="form__inner">
        <div className="form__direction">
          <CheckModule
            id="option"
            text="request options"
            turn={isOptions}
            onClick={onClick}
            style={{ marginTop: "15px" }}
          />
          {isOptions ? (
            <>
              <div className="CheckBlock">
                <div className="checkBlock__direction">
                  <CheckModule
                    onClick={() => setTogle("body")}
                    disable={
                      method !== "get" &&
                      method !== "head" &&
                      method !== "options" &&
                      method !== "delete"
                    }
                    id="body"
                    text="Body"
                    turn={togle !== undefined && togle === "body"}
                    style={{ marginTop: "15px" }}
                  />
                  <CheckModule
                    onClick={() => {
                      setTogle("header");
                    }}
                    id="header"
                    text="Headers"
                    turn={togle !== undefined && togle === "header"}
                    style={{ marginTop: "15px" }}
                  />
                  <CheckModule
                    onClick={() => setTogle("query")}
                    id="query"
                    text="Query"
                    turn={togle !== undefined && togle === "query"}
                    style={{ marginTop: "15px" }}
                  />
                </div>
              </div>
              <BorderModule
                noSinpet={togle === "body"}
                displayData={
                  (togle === "body" && <BodyModule />) ||
                  (togle === "header" && (
                    <ValueCheckModule key={"headers"} name={"headers"} />
                  )) ||
                  (togle === "query" && (
                    <ValueCheckModule key={"query"} name={"query"} />
                  )) ||
                  null
                }
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
export default FormModule;
