import ReactDOM from "react-dom/client";
import "./global.css";
import { router } from "./RouterAPI";
import ClientManagmentProvider from "./Wrappers/ClientManagmentProvider";
import CustomRouterProvider from "./Wrappers/CustomRouterProvider";
import { HelmetProvider } from "react-helmet-async";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <HelmetProvider>
    <ClientManagmentProvider>
      <CustomRouterProvider router={router} />
    </ClientManagmentProvider>
  </HelmetProvider>
);
