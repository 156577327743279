import { giveImage } from "../restAPI/api/personalAPI";
import SetBackground from "./setBackground.helper";

export default async function ifImageExistHelper(data: any) {
  let url = null;
  if (!data.error) {
    const image: string = data && data.personal.theme;
    if (image && image !== "default") {
      const theme = image.replaceAll(" ", "");
      const serverImage = await giveImage(theme);
      if (serverImage) {
        url = URL.createObjectURL(serverImage);
      }
    }
  }
  SetBackground(url);
}
