import { useState, useContext, useEffect } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { clientContext } from "../../contextAPI";
import { contextValue } from "../../contextAPI/contex.interface";
import useIsShadowHook from "./hooks/isShadow.hook";
import onClick from "./helper/onClick.helper";

export default function HeaderHelper() {
  const match = useMatch("/");
  const navigate = useNavigate();
  const [isMain, setIsMain] = useState<boolean>(false);
  const { state, setState } = useContext<contextValue>(clientContext);

  useEffect(() => {
    setIsMain(match ? true : false);
  }, [window.location.pathname]);
  const { isShadow } = useIsShadowHook(state.shadow);
  const onClickHeader = () => onClick(state, setState, navigate);
  return { isMain, state, onClickHeader, isShadow };
}
