import { useReducer } from "react";
import { clientContext, defaultValue } from "../contextAPI";
import { mainReducer } from "../contextAPI/reducer";

export default function ClientManagmentProvider({ children }: any) {
  const [state, setState] = useReducer(mainReducer, defaultValue);
  const value = { state, setState };
  return (
    <clientContext.Provider value={value}>{children}</clientContext.Provider>
  );
}
