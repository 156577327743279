import "./Dialog.setle.scss";
import { cloneElement } from "react";
export default function DialogWrapper({
  children,
  text,
  booleanName,
  shadow,
  setState
}: {
  shadow: any,
  setState: any,
  children: React.ReactElement;
  text: string;
  booleanName: string;
}) {
  return shadow[booleanName as keyof Object] ? (
    <div className="dialog">
      <div className="dialog__inner">
        <div className="dialog__direction">
          <div className="header">
            <button
              className="close__btn"
              onClick={() =>
                setState({
                  type: "shadow",
                  payload: { ...shadow, [booleanName]: false },
                })
              }
            >
              x
            </button>
            <p className="dialog__mainText">{text}</p>
          </div>
          {cloneElement(children, { setState, shadow })}
        </div>
      </div>
    </div>
  ) : null;
}
