import { MouseEventHandler, useState, useEffect, useRef } from "react";
import "./ButtonModule.style.scss";
export default function ButtonModule({
  children,
  onClick,
  className,
  backgroundColor = null,
  color = null,
}: {
  children: any;
  onClick: MouseEventHandler<HTMLElement>;
  className?: string;
  color?: string | null;
  backgroundColor?: string | null;
}) {
  const [style, setStyle] = useState<Object>({});
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [textColor, setTextColor] = useState<string | null>(null!);
  useEffect(() => {
    setStyle({ [`--backgroundColor`]: backgroundColor || 'goldenrod' });
    const textLocal = color ? color : null;
    setTextColor(textLocal);
  }, [color, backgroundColor]);
  return (
    <button
      style={style}
      id="shadowButton"
      ref={buttonRef}
      onMouseEnter={(e: any) => {
        if (textColor) e.target.style.color = textColor;
      }}
      onMouseLeave={() => {
        if (buttonRef.current) buttonRef.current.style.color = "black";
      }}
      className={className}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
