import { Outlet, Link } from "react-router-dom";
import { svg, svgDark } from "../../helper/svg";
import IndexHelper from "../../index.helper";
import ShadowModule from "../../Components/Shadows/ShadowLobby/Shadow.module";
import "./Header.style.scss";
import HeaderHelper from "./Header.helper";
export default function HeaderWrapper() {
  IndexHelper();
  const { state, isMain, onClickHeader, isShadow } = HeaderHelper();
  return (
    <div className="container">
      <header className="header">
        <div className="header__inner">
          <nav className="header__direction">
            <div className="logo">
              <Link aria-label="Check API text as a button to navigate to the home page." to={"/"}> {state.theme ? svgDark : svg}</Link>
            </div>
            {isMain ? (
              <p
                onClick={onClickHeader}
                className="header__btn"
                style={{ color: state.theme ? "white" : "black" }}
              >
                {state.user.isLoggined ? "Log-out" : "Log-in"}
              </p>
            ) : null}
          </nav>
        </div>
      </header>
      <Outlet />
      <img
        src="/"
        style={{
          zIndex: -1,
          position: "fixed",
          left: 0,
          top: 0,
          height: "100vh",
          width: "100vw",
        }}
        width={"100vh"}
        height={"100vw"}
        alt="This image is the background image that the user wants to see on the screen in the background of the content"
        id="backgroundImage"
      />
      {isShadow && <ShadowModule />}
    </div>
  );
}
