import jwtDecode from "jwt-decode";
import { AxiosResponse } from "axios";

export const returnError = (err: any) => ({
  error:
    err.response.data.error ||
    err.response.data.message ||
    "Something went wrong.",
});
const _saveTokenAndDecode = (accessToken: any) => {
  localStorage.setItem("token", accessToken);
  return jwtDecode(accessToken);
};
export const ifAccesTokenExist = (response: AxiosResponse) => {
  const accessToken = response.data.accessToken;
  let decoded = null;
  if (response.data && accessToken) {
    decoded = _saveTokenAndDecode(accessToken);
  }
  return decoded;
};
