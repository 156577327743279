import { SetState } from "../../../../contextAPI/contex.interface";
import "./Input.style.scss";

export default function MethodChoiseModule({
  setState,
}: {
  setState: SetState;
}) {
  return (
    <div className="input_asinput">
      <select
        onChange={(e) => setState({ type: "method", payload: e.target.value })}
        className="input_method"
      >
        <option value="get">GET</option>
        <option value="post">POST</option>
        <option value="put">PUT</option>
        <option value="delete">DELETE</option>
        <option value="patch">PATCH</option>
        <option value="options">OPTIONS</option>
        <option value="head">HEAD</option>
      </select>
    </div>
  );
}
