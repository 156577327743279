import { createContext, useReducer } from "react";
import { contextValue, IInit } from "./contex.interface";

const theme = JSON.parse(localStorage.getItem("theme") || "false");
const inRefresh = JSON.parse(localStorage.getItem("inRefresh") || "false");
export const defaultValue: IInit = {
  theme: theme,
  contentData: { data: null, isSubmited: undefined },
  inRefresh: inRefresh,
  shadow: {
    isFullScreen: false,
    isUploadClicked: false,
    isLoading: false,
    isNotification: false,
    isSuggestion: false,
    canCreateAcc: false
  },
  method: 'get',
  isOptions: false,
  body: null,
  headers: {},
  query: {},
  user: { isLoggined: false, data: null, isTheme: false },
};
const clientContext = createContext<contextValue>({
  state: defaultValue,
  setState: () => {},
});

export { clientContext };
