import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { clientContext } from "./contextAPI";
import { contextValue } from "./contextAPI/contex.interface";
import { refresh } from "./restAPI/api/userAPI";
import ifActivatedSaveUser from "./helper/ifActivatedSave.helper";
import ifImageExistHelper from "./helper/ifImageExist.helper";
import { trackPageView } from "./helper/ga";
import isMoreThen from "./helper/isMoreThen";

export default function IndexHelper() {
  const {
    setState,
    state: { shadow },
  } = useContext<contextValue>(clientContext);
  const { pathname } = useLocation();
  async function boot() {
    const data: any = await refresh();
    ifActivatedSaveUser(data, setState);
    await ifImageExistHelper(data);
    setState({ type: "shadow", payload: { ...shadow, isLoading: false } });
    if (
      data.isActivated &&
      !data.login &&
      !pathname.includes("createAccount")
    ) {
      setState({ type: "shadow", payload: { canCreateAcc: true } });
    }
  }
  useEffect(() => {
    trackPageView(pathname);
    setState({ type: "shadow", payload: { ...shadow, isLoading: true } });
    boot();
  }, [pathname]);
  const isBigger = isMoreThen(2047);
  useEffect(() => {
    return () => {
      if (shadow.isLoading) {
        const isAlready = localStorage.getItem("forLargerDevices") || "false";
        if (isBigger && !JSON.parse(isAlready)) {
          const mainText =
            "Your current device is not supported well, although we have tried to support 2k displays and larger, some data may not display as intended";
          const yesText = "Okay, i understood";
          const noText = "Do not show this message anymore";
          const fn = (e: any, answer: boolean) => {
            if (!answer) {
              localStorage.setItem("forLargerDevices", "true");
            }
            setState({
              type: "shadow",
              payload: { isNotification: false },
            });
          };
          setState({
            type: "shadow",
            payload: {
              isNotification: { mainText, yesText, noText, fn },
            },
          });
        }
      }
    };
  }, [shadow.isLoading]);
}
// @media(min-width: 3840px){
//   font-size: 6em !important;
// }
