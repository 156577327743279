import { MouseEvent, ChangeEventHandler, Dispatch, SetStateAction } from "react";
import createUUID from "../../../helper/creatUUID.helper";
import InputModule from "../../Content/Section/Form/Input/Input.module";

interface IOnClickHelper {
  setInputs: Dispatch<SetStateAction<JSX.Element[]>>;
  onChange: ChangeEventHandler<HTMLInputElement>;
}
export default function onClickHelper({ setInputs, onChange }: IOnClickHelper) {
  return function onClick(e: MouseEvent<HTMLInputElement> & {target: {className: string}}) {
    if (e.target.className === "check__check") {
      const keyID = createUUID();
      setInputs((prev: JSX.Element[]) => [
        ...prev,
        <InputModule
          key={keyID}
          id={keyID}
          onChange={onChange}
          onClick={onClick}
        />,
      ]);
    }
  }
}
