import { useEffect } from "react";
import { Link } from "react-router-dom";
import { ERROR_TYPE } from "../AuthorizationVariant.module";

let content: any = null;
let text: any = null;

export default function useReactError(dataLocal: any, dispatch: any) {
  useEffect(() => {
    __checkErrorType(dataLocal);
    if (content || text) {
      dispatch({
        type: "",
        payload: { suggestion: { text, content } },
      });
      content = null;
      text = null;
    }
  }, [dataLocal.error?.type]);
}
function __makeDefault(dataLocal: any) {
  if (dataLocal.suggestion?.text || dataLocal.suggestion?.content) {
    content = <Link to={"/authorization/signup"}>Create then</Link>;
    text = "Don`t have an account?";
  }
}

function __checkErrorType(dataLocal: any) {
  switch (dataLocal.error?.type) {
    case ERROR_TYPE.FILL_FIELDS:
      __makeDefault(dataLocal);
      break;
    case ERROR_TYPE.LOGIN_OR_PASSWORD:
      __ifLoginOrPassword();
      break;
    case ERROR_TYPE.ACC_NOT_FOUND:
      __makeDefault(dataLocal);
      break;
    case ERROR_TYPE.ACCOUNT_IS_TAKEN:
      __makeDefault(dataLocal);
      break;
  }
}
function __ifLoginOrPassword() {
  content = (
    <Link to={"/authorization/resetPassword"}>Reset the password then</Link>
  );
  text = "Forgot the password?";
}
