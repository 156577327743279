import { useEffect } from "react";
import { sendRequest } from "../../../../../restAPI/api/sendlerAPI";
import inputService from "../Input.services";
import { IInit, SetState } from "../../../../../contextAPI/contex.interface";

interface IUseWhenSUbmited {
  inputState: string;
  setState: SetState;
  state: IInit;
}
export default function useWhenSubmited({
  inputState,
  setState,
  state,
}: IUseWhenSUbmited) {
  const {
    headers,
    body,
    method,
    inRefresh,
    isOptions,
    contentData: { isSubmited },
  } = state;
  useEffect(() => {
    if (isSubmited) {
      (async () => {
        // refactor
        const newHeaders: Object[] = [];
        for(const index in headers){
          const item = headers[index];
          if(item['isClicked']){
            const newItem = {
              header: item['name'],
              value: item['value'],
            }
            newHeaders.push(newItem);
          }
        }
        const data = await sendRequest({
          url: inputState,
          method: method,
          body: body,
          headers: newHeaders,
          isOptions,
        });
        if (inRefresh) {
          localStorage.setItem("responseData", JSON.stringify(data));
          window.location.reload();
        } else {
          setState({
            type: "contentData",
            payload: { data: data, isSubmited: undefined },
          });
        }
      })();
    }
  }, [isSubmited]);
}
