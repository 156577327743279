import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { SetState } from "../../../contextAPI/contex.interface";

interface IOnChange {
  setState: SetState;
  name: string;
  state: Object;
  setOldState: Dispatch<SetStateAction<Object>>;
}
export type evalue = {
  isClicked: boolean;
  className: string;
  name: string;
  value: string;
};
export default function onChangeHelper({
  setState,
  name,
  setOldState,
  state
}: IOnChange) {
  return function onChange(
    e: ChangeEvent<HTMLInputElement> & { value: evalue }
  ) {
    const infoObj = e.value;
    const obj = {
      [e.target.id]: {
        isClicked: infoObj.isClicked,
        className: infoObj.className,
        name: infoObj.name,
        value: infoObj.value,
      },
    };
    //@ts-ignore
    setOldState({...state[name], ...obj});
    setState({
      type: name,
      payload: obj,
    });
  };
}
