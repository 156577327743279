import { useContext } from "react";
import { clientContext } from "../contextAPI";
import { contextValue } from "../contextAPI/contex.interface";

export default function ReturnThemeHelper() {
  const {state: {theme, inRefresh, shadow: {isFullScreen}}} = useContext<contextValue>(clientContext);
  const bodyStyle = document.body.style;
  bodyStyle.backgroundColor = `${theme ? `#282626` : "white"}`;
  return {
    isFullScreen,
    backgroundColorInput: theme ? "white" : "black",
    textColor: !theme ? "white" : "black",
    theme,
    inRefresh
  };
}
