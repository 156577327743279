import ButtonModule from "../ButtonForShadows/ButtonModule";
import "./Notification.style.scss";

function NotificationModule({
  shadow,
  setState,
  onClickFrom,
  yesText = 'Yes',
  noText = 'No'
}: {
  shadow?: any;
  setState?: any;
  onClickFrom?: any;
  yesText?: string;
  noText?: string
}) {
  const onClick = (e: any, answer: boolean) => {
    onClickFrom(e, answer);
  };
  return (
    <div className="notification">
      <div className="notification__buttonBlock">
        <ButtonModule
          className="notification__btn"
          onClick={(e) => onClick(e, true)}
        >
          {yesText}
        </ButtonModule>
        <ButtonModule
          className="notification__btn"
          onClick={(e) => onClick(e, false)}
        >
          {noText}
        </ButtonModule>
      </div>
    </div>
  );
}
export default NotificationModule;
