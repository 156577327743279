import "./Display.style.scss";
import { useEffect, useRef, useState } from "react";
import ScrollButtonModule from "./ScrollButton/ScrollButton.module";
import Code from "./Code";
function CodeSnipet({
  displayData,
  type,
}: {
  displayData: string | JSX.Element | any | null;
  type: string;
}) {
  const [isNeeded, setIsNeed] = useState<boolean>(false);
  const codeREF = useRef<HTMLPreElement>(null);

  useEffect(() => {
    if (codeREF.current && displayData) {
      const boundries = codeREF.current.getBoundingClientRect();
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;
      setIsNeed(boundries.bottom > viewportHeight);
    }
  }, [displayData]);
  return (
    <pre style={{ height: "inherit" }}>
      <ScrollButtonModule refOfElement={codeREF} isNeeded={isNeeded} />
      <Code
        codeREF={codeREF}
        content={displayData}
        type={type}
        key={"hightlight"}
      />
    </pre>
  );
}
export default CodeSnipet;
