import "../Component.style.scss";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import BodyHelper from "./Body.helper";
import isMoreThen from "../../../../../helper/isMoreThen";
function BodyModule() {
  const { text, setText } = BodyHelper();
  const is4K = isMoreThen(3840);
  return (
    <div className="body">
      <AceEditor
        fontSize={is4K ? "2.5em" : "12px"}
        width="auto"
        enableLiveAutocompletion={true}
        value={text}
        mode="json"
        onChange={(newValue) => setText(newValue)}
      />
    </div>
  );
}
export default BodyModule;
