export const trackPageView = (url) => {
  window.gtag("config", "YOUR_TRACKING_ID", { page_path: url });
};

export const trackEvent = (category, action, label) => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
  });
};
