import axiosInstenceAuth from "../axios/authAxios";
import axios, { AxiosResponse } from "axios";

export async function uploadImage(image: Blob | string) {
  const formData = new FormData();
  formData.append("image", image);
  const { data } = await axiosInstenceAuth.post(
    "/personal/uploadImage",
    formData,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return data;
}
export async function deleteImage() {
  const { data } = await axiosInstenceAuth.get("/personal/resetImage", {
    withCredentials: true,
  });
  return data;
}
export async function giveImage(image: string) {
  try {
    const response: AxiosResponse = await axios.get(image, {
      baseURL: process.env.REACT_APP_SERVER_URL,
      responseType: "blob",
    });
    return response.data;
  } catch (error) {
    return null;
  }
}
