import "./Content.style.scss";
import SearchModule from "./Search/Search.module";
import SectionModule from "./Section/Section.module";

function ContentModule() {
  return (
    <article className="content">
      <div className="content__inner">
        <div className="content__direction">
          <SearchModule />
          <SectionModule/>
        </div>
      </div>
    </article>
  );
}
export default ContentModule;
